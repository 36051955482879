import { mapGetters } from 'vuex';
import * as Constants from '@/utils/crm/crm-constants';
import ProgressPills from "@/views/common/ProgressPills.vue";
import ProgressRing from "@/views/common/ProgressRing.vue";
import BienImmos from "@/views/bien-immo/BienImmos.vue";
import IndicatorBlock from "@/views/common/IndicatorBlock.vue";
import ProgramInterlos from "@/views/program/ProgramInterlos.vue";
import { biListType } from '@/utils/crm/interfaces';
import { dateFormatter } from '@/utils/crm-mixin';


function etatShouldOverride(etat, etatId) {
    return etat.overrides && etat.overrides.includes(etatId);
}


export default {
    props: ["id"],
    components: {
        BienImmos,
        IndicatorBlock,
        ProgramInterlos,
        ProgressPills,
        ProgressRing,
    },
    mixins: [ dateFormatter ],
    data() {
        return {
            program: {},
            interlos: {},
            type: biListType.program,
        };
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading',
        }),
        etatId() {
            return this.program?.statuscode;
        },
        pills() {
            if (!this.program) return [];
            const pills:any = Constants.PV_ETATS_PROGRESS.map(etat => Object.assign({}, etat));
            pills.forEach(pill => {
                pill.label = pill.show || Constants.PV_ETAT.find(etat => etat.id === pill.id).label;
                if (etatShouldOverride(pill, this.etatId)) pill.label = Constants.PV_ETAT.find(etat => etat.id === this.etatId).label;
            });
            return pills;
        },
        pillId() {
            if (!this.etatId) return 0;
            const index = Constants.PV_ETATS_PROGRESS.findIndex((etat) => {
                return etat.id === this.etatId || etatShouldOverride(etat, this.etatId);
            })
            return index === -1 ? 3 : index;
        },
    },
    methods: {
        load() {
            this.$store.dispatch("program/getById", this.id).then((program) => {
                // console.log(program);
                this.program = program;
            });
            this.$store.dispatch("program/getInterlos", this.id).then((interlos) => {
                // console.log(interlos);
                this.interlos = interlos;
            });
        }
    },
    watch: {
        id() {
            this.load();
        }
    },
    mounted() {
        this.load();
        this.$refs.bienimmos.refresh();
    },
};
