<style lang="scss">
@import "./Program";
</style>

<template>
  <div id="program">
    <Header>
      <span>{{ program.qua_name }}</span>
      <small v-if="program.qua_esi" class="fw-light ms-2"
        >Réf. {{ program.qua_esi }}</small
      >
      <template #entity>Programme de vente</template>
    </Header>
    <div class="row g-0">
      <div class="col-xl-9">
        <div class="supercard p-3 p-md-4">
          <div class="d-none d-lg-flex mb-5">
            <ProgressPills :pills="pills" :pillId="pillId" />
          </div>
          <h5 class="text-info mb-3">Caractéristiques</h5>
          <div class="row row-cols-sm-2 row-cols-lg-3 g-3 g-md-4">
            <div class="col">
              <div class="mb-2">
                <span class="fw-light">Référence : </span
                >{{ program.qua_esi || "vide" }}
              </div>
              <div class="text-truncate mb-2 mb-lg-0">
                <span class="fw-light">Mandant : </span
                >{{ program.qua_nomdumandant }}
              </div>
              <div class="d-lg-none">
                <span class="fw-light">État : </span>{{ program.statuscode_label }}
              </div>
            </div>
            <div class="col">
              <div class="mb-1">
                <fa
                  icon="calendar"
                  class="fw-light me-1"
                  title="Date de lancement de commercialisation"
                />
                {{ df(program.qua_datelancementcommercialisation) || 'Indéterminée' }}
              </div>
              <div>
                <span class="badge badge_primary_outline" v-if="program.qua_aful"
                  >AFUL</span
                >
                <span class="badge badge_primary_outline" v-if="program.qua_asl"
                  >ASL</span
                >
                <span class="badge badge_primary_outline" v-if="program.qua_copro"
                  >COPRO</span
                >
              </div>
            </div>
          </div>
        </div>
        <h5 class="p-3 p-md-4 m-0 text-info">Indicateurs</h5>
        <div class="supercard px-3 pb-3 px-md-4 pb-md-4">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-5 g-3 g-md-4 indicators">
            <div class="col">
              <div class="card h-100">
                <div class="card-body indicator">
                  <div class="indicator_title">Volume vente</div>
                  <div class="indicator_body">
                    <div class="indicator_col">
                      <IndicatorBlock :value="program.mso_bi" foot="Total" />
                      <IndicatorBlock
                        :value="program.mso_biannee"
                        foot="Exercice"
                        class="text-info"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body indicator">
                  <div class="indicator_title">C.A.</div>
                  <div class="indicator_body">
                    <div class="indicator_col">
                      <IndicatorBlock
                        :value="program.mso_ca"
                        :money="true"
                        foot="Total"
                      />
                      <IndicatorBlock
                        class="text-info"
                        :value="program.mso_caannee"
                        :money="true"
                        foot="Exercice"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body indicator">
                  <div class="indicator_title">Taux d'écoulement</div>
                  <div class="indicator_body">
                    <ProgressRing :percent="program.qua_tauxecoulement" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body indicator">
                  <div class="indicator_title">Stock cessible</div>
                  <div class="indicator_body">
                    <IndicatorBlock
                      :value="program.qua_totallogementscessiblesrollup"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body indicator">
                  <div class="indicator_title">Taux d'entretiens réalisés</div>
                  <div class="indicator_body">
                    <ProgressRing :percent="program.tauxentretiensrealises" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BienImmos ref="bienimmos" :type="type" :programId="id" />
      </div>
      <div class="col-xl-3 p-3 p-md-4 ps-xl-0">
        <ProgramInterlos :row="interlos" :mail-subject="'Programme de vente: ' + program.qua_name" />
      </div>
    </div>
  </div>
</template>

<script src="./Program.ts" />
