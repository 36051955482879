<template>
  <router-link
    :to="{
      name: 'bien-immo',
      params: { id: bienimmo.qua_bienimmobilierid },
    }"
    class="rowcard"
  >
    <div class="card h-100">
      <div class="card-header border-bottom">
        <div class="d-sm-flex">
          <h5 class="card-title mb-0 flex-grow-1">
            {{ bienimmo.qua_nature_label }} &bull; Réf. {{ bienimmo.qua_name }}
          </h5>
          <MiniBI :bienimmo="bienimmo" />
          <div class="text-truncate ms-sm-3">
            {{ bienimmo.qua_occupevacant_label }} &bull; {{ bienimmo.qua_etat_label }}
          </div>
        </div>
      </div>

      <div v-if="!listTypeProgram" class="card-body border-bottom">
        <div>
          <div class="mb-2">
            <fa icon="map-marker" class="me-1 fw-light" />
            {{ ensemble.qua_adresse1 }}
            {{ ensemble.qua_adresse2 }}
            {{ ensemble.qua_commune_label }}
            {{ ensemble.qua_ville }}
          </div>
          <div class="mb-2">
            <span class="fw-light">Programme :</span>
            {{ ensemble.qua_programmedevente_label || "Non défini" }}
          </div>
          <div>
            <span class="fw-light">Mandant :</span>
            {{ ensemble.qua_mandant_label || "Non défini" }}
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row row-cols-2">
          <div class="col" v-if="isOccupe">
            <div class="text-truncate mb-2">
              <span class="fw-light">Locataire :</span>
              {{ locaprinc.fullname || "Non défini" }}
            </div>
            <div>
              <span class="fw-light">
                Bail <fa icon="calendar" title="Date" />
              </span>
              {{
                df(locaprinc.new_datedupremierbaildulocataire) || "Non défini"
              }}
            </div>
          </div>
          <div class="col" v-if="isOccupe">
            <div class="mb-2">
              <span class="fw-light">Dernier entretien :</span>
              {{ df(dernierEnt.qua_dateentretien) || "Non défini" }}
            </div>
            <div>
              <span class="fw-light">Scoring :</span>
              {{ dernierEnt.qua_scoring_label || "Non défini" }}
            </div>
          </div>
          <div class="col" v-if="!isOccupe">
            <div>
              <span class="fw-light">
                Sorti le <fa icon="calendar" title="Date" />
              </span>
              {{ df(bienimmo.qua_datedesortiedulocataire) || "Non défini" }}
            </div>
          </div>
          <div class="col" v-if="!isOccupe">
            <div>
              <span class="fw-light">Ancien locataire :</span>
              {{ bienimmo.qua_nomdanstatlocatif || "Non défini" }}
            </div>
          </div>
        </div>

        <div v-if="isVenduVentEnc" class="mt-2">
          <span class="fw-light">Acquéreurs :</span>
          {{ bienimmo.qua_nomsacquereur || "Non défini" }}
        </div>
      </div>

    </div>
  </router-link>
</template>

<script src="./RowBienImmo.ts" />
