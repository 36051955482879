import { crmFormatter, dateFormatter } from '@/utils/crm-mixin';
import { extrudeAliasedFields } from '@/utils/crm/crm';
import { BI_ETAT_VENDU, BI_ETAT_VENTEENC } from '@/utils/crm/crm-constants';
import { biListType } from '@/utils/crm/interfaces';

export default {
    props: {
        bienimmo: {},
        type: {
            type: String,
            required: true,
            validator: (value) => value in biListType,
        },
    },
    mixins: [crmFormatter('bienimmo'), dateFormatter],
    computed: {
        listTypeProgram() {
            return this.type === biListType.program;
        },
        isOccupe() {
            return this.bienimmo.qua_occupevacant;
        },
        isVenduVentEnc() {
            return [BI_ETAT_VENDU, BI_ETAT_VENTEENC].includes(this.bienimmo.qua_etat);
        },
        ensemble() {
            return extrudeAliasedFields(this.bienimmo, 'qua_ensembleimmobilier.');
        },
        locaprinc() {
            return extrudeAliasedFields(this.bienimmo, 'contact.');
        },
        dernierEnt() {
            return extrudeAliasedFields(this.bienimmo, 'qua_etudepersonnalisee.');
        },
    },
};
