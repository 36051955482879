<template>
  <div>
    <form class="p-3 pb-0 p-md-4 pb-md-0" @submit.prevent="refresh">
      <div class="d-xxl-flex">
        <div class="d-flex flex-grow-1 me-xxl-2">
          <input
            type="text"
            class="form-control search_input"
            :class="{ 'border-info': filters.search }"
            placeholder="Rechercher"
            v-model="filters.search"
          />
        </div>
        <div class="d-flex text-center mt-1 mt-xxl-0">
          <select
            class="form-select me-1"
            :class="{ 'border-info': filters.destin }"
            v-model="filters.destin"
            @change="refresh"
          >
            <option :value="null">Destination</option>
            <option disabled>──────────</option>
            <option
              v-for="option in toggles.destin"
              :key="option.ud"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
          <select
            v-if="!listTypeOccVac"
            class="form-select me-1"
            :class="{ 'border-info': filters.occvac !== defaultFilters.occvac }"
            v-model="filters.occvac"
            @change="refresh"
          >
            <option :value="null">Occupé/Vacant</option>
            <option disabled>──────────</option>
            <option
              v-for="option in toggles.occvac"
              :key="option.ud"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
          <select
            class="form-select me-1"
            :class="{ 'border-info': filters.etat }"
            v-model="filters.etat"
            @change="refresh"
          >
            <option :value="null">État</option>
            <option disabled>──────────</option>
            <option
              v-for="option in toggles.etat"
              :key="option.ud"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
          <button
            type="button"
            class="btn me-1"
            :class="[ anyActiveFilters ? 'btn-info' : 'btn-secondary' ]"
            @click="resetFilters"
          >
            &#10799;
          </button>
          <button type="submit" class="btn btn-primary">Filtrer</button>
        </div>
      </div>
    </form>
    <div>
      <div class="p-3 p-md-4 d-flex align-items-center">
        <h5 class="m-0" v-if="!loading">
          <span class="text-info">Biens immobiliers :</span>
          {{ totalRecordCount }}
        </h5>
        <h5 v-else>Chargement ...</h5>
        <div class="ms-auto d-flex text-nowrap align-items-center">
          <span class="fw-light me-2">Trié par</span>
          <select class="form-select" v-model="order.attribute" @change="refresh">
            <option value="qua_name">&#8470; de gestion</option>
          </select>
          <div class="btn" @click="order.descending = !order.descending; refresh()">
            <fa
              :icon="order.descending ? 'sort-amount-down' : 'sort-amount-up'"
            />
          </div>
        </div>
      </div>
      <div class="px-3 pb-3 px-md-4 pb-md-4">
        <div class="row row-cols-1 row-cols-xxl-2 g-3 g-md-4">
          <div class="col" v-for="bienimmo in bienimmos" :key="bienimmo.id">
            <RowBienImmo :bienimmo="bienimmo" :type="type" />
          </div>
        </div>
      </div>
      <div class="text-center mb-2">
        <button v-if="moreRecords"
          :class="[
            'btn',
            'btn-primary',
            'ms-2',
            { disabled: loading },
          ]"
          @click="appendNextPage"
        >
          Voir plus
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./BienImmos.ts" />
