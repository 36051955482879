<template>
  <div class="card mb-3 mb-md-4">
    <div class="card-header text-white bg-primary">
      <h5 class="my-2">
        <span>Vos interlocuteurs</span>
      </h5>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-1">
      <div class="col" v-for="interlo in interlos" :key="interlo.id">
        <div class="card-body">
          <div class="fw-light">{{ interlo.label }}</div>
          <div>{{ interlo.name }}</div>
          <div class="text-truncate">
            <fa icon="at" class="fw-light me-2" />
            <a :href="'mailto:' + interlo.email + mailSubjectSuffix">{{
              interlo.email
            }}</a>
          </div>
          <div class="text-truncate">
            <fa icon="phone" class="fw-light me-2" />
            <a :href="'tel:' + interlo.phone">{{ interlo.phone }}</a>
          </div>
        </div>
      </div>
      <div class="col" v-if="!interlos.length">
        <div class="card-body text-muted">Aucun défini</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Constants from "@/utils/crm/crm-constants";
import { extrudeAliasedFields } from "@/utils/crm/crm";

function mapInterlo(interlo, il) {
  const conf = Constants.INTERLO_MAPPING[il.type];
  return Object.assign({}, il, {
    name: interlo[conf.name],
    phone: interlo[conf.phone],
    email: interlo[conf.email],
  });
}

export default {
  props: ["row", "mailSubject", "notaire"],
  computed: {
    interlos() {
      if (!this.row) return [];
      let pvHasNotaire = false;
      const interlos = Constants.INTERLOS.filter(
        (il) =>
          !!this.row[`${il.id}.${Constants.INTERLO_MAPPING[il.type].name}`]
      ).map((il) => {
        if (il.id === Constants.NOTAIRE_MANDANT_INTERLO.id) pvHasNotaire = true;
        const interlo = extrudeAliasedFields(this.row, il.id + ".");
        return mapInterlo(interlo, il);
      });
      if (!pvHasNotaire && !!this.notaire) { // Notaire fallback
        interlos.push(
          mapInterlo(this.notaire, Constants.NOTAIRE_MANDANT_INTERLO)
        );
      }
      return interlos;
    },
    mailSubjectSuffix() {
      if (!this.mailSubject) return "";
      return "?subject=" + this.mailSubject;
    },
  },
};
</script>
