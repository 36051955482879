// @ts-nocheck
import { mapGetters } from 'vuex';
import * as Constants from '@/utils/crm/crm-constants';
import RowBienImmo from "@/views/bien-immo/RowBienImmo.vue";
import { biListType } from '@/utils/crm/interfaces';


const defaultData = () => {
    return {
        filters: {
            destin: null,
            occvac: null,
            etat: null,
            search: '',
        },
        order: { attribute: 'qua_name', descending: true, },
        page: 1,
        pagingCookie: null,
    };
};


export default {
    components: {
        RowBienImmo,
    },
    props: {
        type: {
            type: String,
            required: true,
            validator: (value) => value in biListType,
        },
        programId: {
            type: String,
            required: false,
        }
    },
    data() {
        const biSettings = Constants.BI_ETAT_BY_LIST_TYPE[this.type];
        const data = {
            bienimmos: [],
            toggles: {
                destin: Constants.BI_DESTIN,
                occvac: Constants.BI_OCCVAC,
                etat: biSettings.etat.toggles,
            },
            moreRecords: false,
            totalRecordCount: 0,
            SMIloaded: null,
            biSettings,
            defaultFilters: defaultData().filters,
        };
        Object.assign(data, defaultData());
        data.filters.destin = Constants.BI_DESTIN_HABITATION;
        return data;
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading',
            SMIKey: 'common/SMIKey',
        }),
        listTypeOccVac() {
            return this.biSettings.occvac.default !== null;
        },
        anyActiveFilters() {
            return Object.keys(this.filters).some(key => this.filters[key] !== this.defaultFilters[key]);
        },
    },
    methods: {
        resetFilters() {
            Object.assign(this.$data, defaultData());
            this.refresh();
        },
        getPage() {
            if (this.loading) return;
            const payload = {
                type: this.type, // CRM
                page: this.page,
                etat: [],
                order: this.order.attribute,
                desc: this.order.descending,
            };
            if (this.pagingCookie) {
                payload.pagingCookie = this.pagingCookie;
            }
            if (this.type === biListType.program) {
                payload.programId = this.programId; // CRM
                payload.qua_programmedeventeid = this.programId; // API
            }
            payload.etat = this.biSettings.etat.default;
            const occvac = this.biSettings.occvac.default;
            if (occvac !== null) {
                payload.occvac = occvac;
            }
            if (this.filters.etat) {
                payload.etat = [].concat(this.filters.etat);
            }
            if (payload.etat.length) {
                if (payload.etat[0] < 0) {
                    payload.excludeEtat = true;
                }
                payload.etat = payload.etat.map(etat => Math.abs(etat));
            }
            if (this.filters.occvac !== null) {
                payload.occvac = this.filters.occvac;
            }
            if (this.filters.destin !== null) {
                payload.destin = this.filters.destin;
            }
            if (this.filters.search !== '') {
                payload.search = this.filters.search;
            }
            return this.$store.dispatch('bienimmo/getList', payload).then(response => {
                // console.log(response);
                this.pagingCookie = response.pagingCookie;
                this.moreRecords = response.isMoreRecords;
                this.totalRecordCount = response.totalCount;
                return response.data;
            });
        },
        appendNextPage() {
            this.page++;
            this.getPage().then((bienimmos) => {
                this.bienimmos = this.bienimmos.concat(bienimmos);
            });
        },
        refresh() {
            this.page = 1;
            this.pagingCookie = null;
            this.getPage().then((bienimmos) => {
                this.SMIloaded = this.SMIKey;
                this.bienimmos = bienimmos;
            });
        },
        routeEntered() {
            if (this.SMIloaded !== this.SMIKey) this.refresh();
        },
    },
    watch: {
        programId() {
            this.refresh();
        },
    },
};
