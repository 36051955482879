<style lang="scss">
@import "./ProgressPills";
</style>

<template>
  <div class="progress_pills">
    <div class="progress">
      <div
        class="progress_bar"
        :style="{ width: getLeftForIndex(pillId) }"
      ></div>
    </div>
    <span
      v-for="(pill, id) in pills"
      :key="id"
      :class="[
        'progress_text',
        {
          progress_text_active: id === pillId,
          progress_text_passed: id < pillId,
        },
      ]"
      :style="{ left: getLeftForIndex(id) }"
      >{{ pill.show || pill.label }}</span
    >
    <button
      v-for="(pill, id) in pills"
      :key="id"
      type="button"
      :class="[
        'progress_pill',
        {
          progress_pill_active: id === pillId,
          progress_pill_passed: id < pillId,
        },
      ]"
      :style="{ left: getLeftForIndex(id) }"
    ></button>
  </div>
</template>

<script>
export default {
    props: ['pills', 'pillId'],
    methods: {
        getLeftForIndex(index) {
            return (index / (this.pills.length - 1)) * 100 + "%";
        },
    }
};
</script>
